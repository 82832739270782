"use client";

import { FC, memo, useState, useCallback, useMemo } from "react";
import { Button, Box } from "@mui/material";
import { CategoryEvents } from "@/gql/graphql";
import CancelIcon from "@mui/icons-material/Cancel";
import Image from "next/image";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// Types
interface CategoryButtonProps extends CategoryEvents {
  selected: boolean;
  onClick: (id: number) => void;
  handleClear: () => void;
}

interface Props {
  getSelectedCategory: (id: number | null) => void;
  categories?: CategoryEvents[];
}

// Styles
const buttonStyles = {
  base: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "#ffffff",
    width: "90px",
    height: "90px",
    py: 1,
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    cursor: "pointer",
    mx: 1,
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cancelIcon: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      background: "black",
      color: "white",
      borderRadius: "50%",
    },
  },
  imageContainer: {
    width: "44px",
    height: "44px",
    position: "relative",
  },
  categoryName: {
    mt: 1,
    fontSize: "12px",
    textTransform: "capitalize",
    color: "black",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingX: "5px",
    maxWidth: "80px",
    textAlign: "center",
  },
} as const;

// Static Swiper styles and breakpoints outside the component to avoid re-creation.
const SWIPER_STYLE = `
  .swiper-button-next, .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 18px;
    font-weight: bold;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  .swiper-pagination-bullet-active {
    background-color: #FFFFFFFF;
  }
  .swiper-pagination-bullet {
    background-color: #B4B4B4FF;
  }
`;

const SWIPER_BREAKPOINTS = {
  320: { slidesPerView: 3 },
  480: { slidesPerView: 3 },
  618: { slidesPerView: 5 },
  835: { slidesPerView: 6 },
  820: { slidesPerView: 7 },
  1024: { slidesPerView: 8 },
  1280: { slidesPerView: 10 },
};

// Category Button component
const CategoryButton: FC<CategoryButtonProps> = memo(
  ({ selected, onClick, coverImage, name, id, handleClear }) => {
    const handleClearClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();
        handleClear();
      },
      [handleClear]
    );

    const handleButtonClick = useCallback(() => {
      if (selected) {
        handleClear();
      } else {
        onClick(id);
      }
    }, [onClick, id, selected, handleClear]);

    return (
      <Button
        onClick={handleButtonClick}
        variant="contained"
        sx={buttonStyles.base}
        aria-label={`Select category ${name}`}
      >
        {selected && (
          <Box
            component="span"
            onClick={handleClearClick}
            sx={buttonStyles.cancelIcon}
          >
            <CancelIcon />
          </Box>
        )}
        <Box component="div" sx={buttonStyles.imageContainer}>
          <Image
            src={coverImage?.url || ""}
            fill
            decoding="async"
            style={{ objectFit: "cover", borderRadius: "50%" }}
            sizes="44px"
            alt={name}
            priority={false}
          />
        </Box>
        <Box component="span" sx={buttonStyles.categoryName}>
          {name}
        </Box>
      </Button>
    );
  }
);

// Main Slider component
const CategoryHomeMenu: FC<Props> = memo(
  ({ getSelectedCategory, categories }) => {
    const [selectedCategory, setSelectedCategory] = useState<number | null>(
      null
    );

    const handleCategorySelect = useCallback(
      (id: number) => {
        setSelectedCategory(id);
        getSelectedCategory(id);
      },
      [getSelectedCategory]
    );

    const handleCategoryClear = useCallback(() => {
      setSelectedCategory(null);
      getSelectedCategory(null);
    }, [getSelectedCategory]);

    // Memoize the style element to avoid re-creating it on each render.
    const styleElement = useMemo(() => <style>{SWIPER_STYLE}</style>, []);

    return (
      <Box sx={{ p: 1, borderRadius: 2 }}>
        {styleElement}
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={10}
          slidesPerView={10}
          breakpoints={SWIPER_BREAKPOINTS}
        >
          {categories?.map((category) => (
            <SwiperSlide key={category.id}>
              <CategoryButton
                {...(category as CategoryEvents)}
                selected={selectedCategory === category.id}
                onClick={handleCategorySelect}
                handleClear={handleCategoryClear}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    );
  }
);

export default CategoryHomeMenu;
