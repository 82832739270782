"use client";

import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { graphql } from "@/gql";
import { useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
import { CategoryEvents, Event } from "@/gql/graphql";
import useAnonymousId from "@/hooks/useAnonymousId";
import { useSelectCountry } from "@/context/CountryContext";
import CategoryHomeMenu from "./CategoryHomeMenu/CategoryHomeMenu";
import dynamic from "next/dynamic";
import "./HomePage.css";

// Dynamic imports with default loading fallbacks already provided by next/dynamic
const AutocompleteSearchField = dynamic(
  () => import("./AutocompleteSearchField/AutocompleteSearchField")
);
const EventGridHome = dynamic(() => import("./EventGridHome/EventGridHome"));
const DotsLoading = dynamic(() => import("../Commons/DotsLoading/DotsLoading"));
const LoadMoreTrigger = dynamic(
  () => import("./LoadMoreTrigger/LoadMoreTrigger")
);

// Constants moved outside of the component
const QUERY_SIZE = 12;
const DEFAULT_ANONYMOUS_ID = "web-default-user";

// GraphQL query defined outside the component to avoid re-definition
const EVENTS = graphql(`
  query GetEventExplored(
    $take: Int!
    $skip: Int!
    $name: String
    $categoryIds: [Int!]
    $anonymousId: String
    $countryId: Int
  ) {
    getEventExplored(
      take: $take
      skip: $skip
      name: $name
      categoryIds: $categoryIds
      countryId: $countryId
    ) {
      count
      events {
        id
        title
        startedDate
        endedDate
        startedTime
        note
        endedTime
        descriptions
        countUserInterestEvent
        currentUserIsInterest(anonymousId: $anonymousId)
        eventPrices {
          currentUserCurrency
          eventPublicPriceByUser
        }
        countryId
        coverImage {
          id
          url
        }
        categories {
          id
          name
          coverImage {
            id
            url
          }
        }
      }
    }
  }
`);

interface Props {
  events?: Event[];
  categories?: CategoryEvents[];
}

const Home = ({ events = [], categories }: Props) => {
  const { anonymousId, isClient } = useAnonymousId();
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [loadingMore, setLoadingMore] = useState(false);
  const { countryId } = useSelectCountry();

  // Memoize query variables to prevent unnecessary recomputations
  const queryVariables = useMemo(
    () => ({
      skip: 0,
      countryId,
      take: QUERY_SIZE,
      anonymousId: anonymousId || DEFAULT_ANONYMOUS_ID,
      ...(selectedCategory && { categoryIds: [selectedCategory] }),
    }),
    [anonymousId, selectedCategory, countryId]
  );

  const { data, loading, fetchMore } = useQuery(EVENTS, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    returnPartialData: true,
    ssr: false,
  });

  // Memoize processed events list based on data
  const eventDatas = useMemo(() => {
    if (data?.getEventExplored) {
      return data.getEventExplored.events;
    }
    return events;
  }, [data, events]);

  // Calculate if there are more events available for fetching
  const hasMore = useMemo(
    () => (eventDatas?.length ?? 0) < (data?.getEventExplored?.count ?? 0),
    [eventDatas?.length, data?.getEventExplored?.count]
  );

  // Memoize fetchMore handler
  const handleFetchMore = useCallback(() => {
    if (loading || !hasMore || loadingMore) return;
    setLoadingMore(true);
    const currentLength = eventDatas.length || 0;
    fetchMore({
      variables: {
        ...queryVariables,
        skip: currentLength,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEventExplored: {
            ...fetchMoreResult.getEventExplored,
            events: [
              ...prev.getEventExplored.events,
              ...fetchMoreResult.getEventExplored.events,
            ],
          },
        };
      },
    }).finally(() => setLoadingMore(false));
  }, [eventDatas, loading, fetchMore, hasMore, queryVariables, loadingMore]);

  // Persist anonymous id to localStorage only on client side
  useEffect(() => {
    if (typeof window === "undefined") return;
    const storedAnonymousId = localStorage.getItem("anonymousId") || uuidv4();
    localStorage.setItem("anonymousId", storedAnonymousId);
  }, []);

  // Memoize sx objects for the Box components to avoid re-creation on every render.
  const headerSx = useMemo(
    () => ({
      mb: 2,
      position: "sticky",
      top: "5.85vh",
      zIndex: 999,
      background: "rgba(0, 0, 0, 0.9)",
      paddingTop: isDesktop ? "5px" : "20px",
      paddingBottom: "10px",
    }),
    [isDesktop]
  );

  const mainSx = useMemo(() => ({ flex: 1, my: 5 }), []);

  const loadingSx = useMemo(
    () => ({
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      my: 4,
    }),
    []
  );

  // Render a loading fallback if running on the server or not yet hydrated
  if (!isClient) return <DotsLoading />;

  return (
    <Box sx={{ flex: 1, bgcolor: "black", color: "white", p: 2 }}>
      <Container maxWidth="lg">
        <Box component="header" sx={headerSx}>
          <AutocompleteSearchField sx={{ marginBottom: 2 }} />
          <CategoryHomeMenu
            getSelectedCategory={setSelectedCategory}
            categories={categories || []}
          />
        </Box>
        <Box component="main" sx={mainSx}>
          <EventGridHome
            events={(eventDatas as Event[]) || []}
            anonymousId={anonymousId}
          />
          <LoadMoreTrigger
            hasMore={Boolean(hasMore)}
            loading={loadingMore}
            onVisible={handleFetchMore}
          />
        </Box>
        {loadingMore && (
          <Box sx={loadingSx}>
            <CircularProgress size={35} style={{ color: "white" }} />
          </Box>
        )}
      </Container>
    </Box>
  );
};

// Optionally wrap with React.memo if the props rarely change
export default React.memo(Home);
